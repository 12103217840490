<template>
  <div class="navbar-container main-menu-content d-flex justify-content-start align-items-center">
    <div class="d-flex align-items-center">
      <img src="@/assets/images/logo/job.jpeg" width="50px" />
      <h2 class="ml-2 font-weight-bold">The Pentecost Job Search App</h2>
    </div>

    <horizontal-nav-menu-items :items="PublicMenuItems" class="flex-fill" />

    <div class="menu-buttons d-flex">
      <div class="login-button mr-2">
        <b-link :to="{ name: 'auth-login' }">
          Login</b-link>
      </div>

      <div class="signup-button">
        <b-link :to="{ name: 'auth-register' }">
          Sign Up
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
// import navMenuItems from '@/navigation/horizontal'
import { BLink, } from 'bootstrap-vue'
import PublicMenuItems from "@/navigation/public";
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    BLink,
    HorizontalNavMenuItems,
  },
  computed: {
    navigationItems() {
      return this.PublicMenuItems
    },
  },
  setup() {
    return {
      PublicMenuItems,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
@import '@core/scss/vue/pages/page-public.scss';
</style>
