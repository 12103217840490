<template>
  <b-row v-if="!isFetchingRemoteData" class="pt-1">
    <b-col md="3">
      <div>
        <h5 class="text-black">Contact Information:</h5>
        <p class="pr-5 text-black font-weight-bold" style="font-size: 0.8rem !important;">
         Email: info@thecophq.org<br />
         Telephone: (+233) 571 124 180 | 0570 947 621 <br />Helpline: (+233) 543 308 187<br />
         Address: Gbeshigon Street, La, Accra, Ghana<br />
         (Near Maale Dada Street) <br />Digital Address: GL-020-5834
        </p>

        <div class="my-2 mt-1">
          <a class="d-inline-block mr-1" href="#" target="_blank" rel="noopener noreferrer">
            <feather-icon icon="FacebookIcon" size="20" color="black"></feather-icon>
          </a>
          <a class="d-inline-block mr-1" href="#" target="_blank" rel="noopener noreferrer">
            <feather-icon icon="TwitterIcon" size="20" color="black"></feather-icon>
          </a>
          <a class="d-inline-block mr-1" href="#" target="_blank" rel="noopener noreferrer">
            <feather-icon icon="InstagramIcon" size="20" color="black"></feather-icon>
          </a>
          <a class="d-inline-block mr-1" href="#" target="_blank" rel="noopener noreferrer">
            <feather-icon icon="YoutubeIcon" size="20" color="black"></feather-icon>
          </a>
        </div>
      </div>
    </b-col>

    <b-col md="3">
      <div>
        <h4 class="text-white font-weight-bold">Privacy Policy</h4>
        <p class="pr-5 text-black">
          Our website's privacy policy can be found <b-link class="" :to="{ name: 'privacy' }">here</b-link>
        </p>

        <h4 class="text-white font-weight-bold">Terms and Conditions</h4>
        <p class="pr-5 text-black">
          Our website's terms and conditions can be found <b-link class="" :to="{ name: 'terms' }">here</b-link>
        </p>
      </div>
    </b-col>

     <b-col md="3">
      <div>
        <h4 class="text-white font-weight-bold">Job Search Tips:</h4>
        <p class="pr-5 text-black">
          Looking for a job can be tough, so we've put together some helpful tips and advice on our blog. <b-link class="" :to="{ name: 'blog' }">Check it out</b-link>
        </p>
      </div>
    </b-col>

    <b-col md="3">
      <div>
        <h4 class="text-white font-weight-bold">Job Posting Guidelines:</h4>
        <p class="pr-5 text-black">
          If you're a job provider, make sure to read our job posting guidelines to create effective job listings that attract top talent.
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
} from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg
  },
  computed: {
    isFetchingRemoteData() {
      return this.$store.getters[`navigation/isFetchingRemoteData`];
    },
  }
}
</script>

<style lang="scss" scoped>
.text-black {
  color: black !important
}

.text-yellow {
  color: yellow
}
.font-md {
  font-size: 12px;
}
.font-lg {
  font-size: 14px;
}
.font-xxl {
  font-size: 25px;
}
h1, h2, h3, h4 {
  font-weight: normal;
}
</style>
